import { IOrder, IOrders, OrderStatus, DeliveryModelCode, OrderDeliveryType, OrderUrgency, AddressState } from "../types/IOrders";
import { ILocation, LocationCode } from "../types/ILocations";

import { Order, OrderStates, OrderStatuses, QueueElement, QueueStatuses } from 'sparrowhub-client-axios';

import { Category, ScriptQueueCategory, ScriptQueueSubcategory, Subcategory } from "../types/DashboardCategories";
import { Timestamp } from "../types/Timestamp";
import { useDummyData } from "../context/DummyDataProvider";
import { IScriptQueueConfig } from "../types/IScriptQueueConfig";
import { PaymentAddress } from "../components/PaymentInput/PaymentInput";

interface IOrderTemp extends IOrder {
  parsedDate?: Date
}

/**
 * clamp a number between minimum and maximum values
 * 
 * @param num number
 * @param min number | undefined
 * @param max number | undefined
 * @returns number
 */
export function clamp(num: number, min: number | undefined, max: number | undefined): number {
  return Math.min(Math.max(num, min || num), max || num);
}

/**
 * generate a random number between minimum and maximum values
 * 
 * @param min number
 * @param max number
 * @returns number
 */
export function randomRange(min: number, max: number): number {
  return (Math.random() * (max - min) + min);
}

/**
 * return a human-readable order number for a given Order
 * 
 * @param order IOrder
 */
export function getOrderNumber(order: IOrder): string {
  return order.platform_order_no || order.order_number || '';
}

/**
 * return the number of items for a given Order
 * 
 * @param order IOrder
 */
export function getNumItems(order: IOrder): number {
  return order.items.reduce((acc: any, item: any) => {
    return acc + (item.qtyToRefund ? item.qty_ordered - item.qtyToRefund : item.qty_ordered);
  }, 0);
}

/**
 * return a formatted string describing the number of items for a given Order
 * 
 * @param order IOrder
 */
export function getNumItemsString(order: IOrder): string {
  const num = getNumItems(order);
  return `${num} ${num === 1 ? 'item' : 'items'}`;
}

/**
 * format a prescription entitlement type
 * 
 * @param entitlement string
 */
export function formatEntitlement(entitlement: string): string {
  switch (entitlement) {
    case 'pbs':
      return 'Medicare';
    case 'safety_net':
      return 'Safety Net';
    case null:
      return '';
    case undefined:
      return '';
    default:
      return entitlement.charAt(0).toUpperCase() + entitlement.slice(1);
  }
}

const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD'
});

/**
 * format a price in dollars as an en-AU string
 * 
 * @param price number
 * @returns string
 */
export function formatPrice(price: number | string): string {
  let priceNum = typeof price === 'number'
    ? price
    : parseFloat(price);
  return formatter.format(priceNum);
}

/**
 * format an integer price in cents to a float price in dollars
 * 
 * @param price number
 * @returns number
 */
export const formatPriceIntToFloat = (price: number): number => {
  return price / 100;
}

/**
 * format a float price in dollars to an integer price in cents
 * 
 * @param price number
 * @returns number
 */
export const formatPriceFloatToInt = (price: number): number => {
  const str = price.toString()
  const int = str.split('.')
  return Number(price.toFixed(2).replace('.', '').padEnd(int.length === 1 ? 3 : 4, '0'))
}

/**
 * reformat a float price in dollars to remove unused decimal places
 * 
 * @param price number
 * @returns number
 */
export const formatPriceFloatToFloat = (price: number): number => {
  return Math.round(price * 10000) / 10000;
}

/**
 * format an Order's delivery or billing address
 * 
 * @param order IOrder
 * @param addressType 'delivery' | 'billing'
 * @returns string
 */
export function formatOrderAddress(order: IOrder, addressType: 'delivery' | 'billing' = 'delivery'): string {
  let street = '';
  switch (addressType) {
    case 'delivery':
      try {
        street = JSON.parse(order.delivery_street).join(' ');
      } catch (error) {
        street = order.delivery_street;
        console.warn('Error parsing order delivery address:', error);
      }
      return `${street}, ${order.delivery_city}, ${order.delivery_state_code}, ${order.delivery_postcode}`;
    case 'billing':
      try {
        street = JSON.parse(order.billing_street).join(' ')
      } catch (error) {
        street = order.billing_street;
        console.warn('Error parsing order billing address:', error);
      }
      return `${street}, ${order.billing_city}, ${order.billing_state_code}, ${order.billing_postcode}`;
  }
}

/**
 * format a Location's address
 * 
 * @param location ILocation
 * @returns string
 */
export function formatLocationAddress(location: ILocation): string {
  return `${JSON.parse(location.address.street || '').join(' ')}, ${location.address.city}, ${location.address.region_code}, ${location.address.postcode}`;
}

/**
 * create a Date object from a Timestamp
 * 
 * @param timestamp Timestamp
 * @returns Date
 */
export function parseTimestamp(timestamp: Timestamp): Date {
  return new Date(Date.parse(timestamp + 'Z'));
}

/**
 * generate a correctly formatted Timestamp ISO string from a Date
 * 
 * @param date Date
 * @returns Timestamp
 */
export function formatTimestampISO(date: Date): Timestamp {
  const dateAsISO = new Date(date).toISOString();
  // return dateAsISO;

  // formatting fix for backend parsing bug
  // trim off millis and Z
  return dateAsISO.substring(0, 19);
}

/**
 * format a Timestamp string for display
 * 
 * @param timestamp Timestamp
 * @param mode 'date' | 'time' | 'time12' | 'datetime'
 * @returns string
 */
export function formatTimestamp(timestamp: Timestamp, mode: 'date' | 'time' | 'time12' | 'datetime' | 'datetime12' | 'filename' = 'date'): string {
  const date = parseTimestamp(timestamp);
  switch (mode) {
    case 'date':
      return date.toLocaleDateString('en-AU');
    case 'time':
      return date.toLocaleTimeString('en-AU', { hour12: false });
    case 'time12':
      return date.toLocaleTimeString('en-AU', { hour12: true, hour: 'numeric', minute: '2-digit' });
    case 'datetime':
      return date.toLocaleString('en-AU', { hour12: false }).replace(',', '');
    case 'datetime12':
      return date.toLocaleString('en-AU', { hour12: true }).replace(',', '');
    case 'filename':
      return date.toLocaleString('en-AU', { hour12: false })
        .replace(', ', '_')
        .replaceAll('/', '-')
        .replaceAll(':', '-');
  }
}

/**
 * generate a correctly formatted Timestamp string for the current time
 * 
 * @returns Timestamp
 */
export function getCurrentTimestamp(): Timestamp {
  return formatTimestampISO(new Date());
}

/**
 * return number of items in the Order
 * 
 * @param order IOrder
 * @returns Timestamp
 */
export function getNumberItems(order: IOrder): number {
  return order.items.reduce((acc, item) => {
    return acc + item.qty_ordered || (item as any).qty;
  }, 0);
}

/**
 * determine if an order has been fully refunded
 * 
 * @param order IOrder
 * @returns boolean
 */
export function isFullRefund(order: IOrder): boolean {
  if (order.status_code !== OrderStatus.Refunded) {
    // console.warn(`Order ${order.id} was checked for full refund, but status_code !== ${OrderStatus.Refunded}`);
    return false;
  }

  // start with refund_adjustment and refund_delivery
  let refundTotal = parseFloat(order.refund_adjustment) + parseFloat(order.refund_delivery);

  // console.log(order);
  // console.log('refund_adjustment', order.refund_adjustment);
  // console.log('refund_delivery', order.refund_delivery);

  // add item quantities refunded
  order.items.forEach(item => {
    refundTotal += item.total * (item.qty_refunded || 0);
    // console.log('item.id', item.id);
    // console.log('total', item.total);
    // console.log('qty_ordered', item.qty_ordered);
    // console.log('qty_shipped', item.qty_shipped);
    // console.log('qty_refunded', item.qty_refunded);
  })

  // final comparison
  // console.log('order total', order.total);
  // console.log('refund total', refundTotal);
  return refundTotal === parseFloat(order.total);
}

/**
 * determine if an order has been partially refunded
 * 
 * @param order IOrder
 * @returns boolean
 */
export function isPartialRefund(order: IOrder): boolean {
  if (order.status_code === OrderStatus.Refunded) {
    // console.warn(`Order ${order.id} was checked for partial refund, but status_code === ${OrderStatus.Refunded}. Order is a full refund.`);
    return false;
  }

  if (order.refund_adjustment && parseFloat(order.refund_adjustment) > 0) {
    // check refund adjustment
    return true;
  } else if (order.refund_delivery && parseFloat(order.refund_delivery) > 0) {
    // check refund delivery
    return true;
  } else {
    // check item refunds
    return order.items.some(item => (item.qty_refunded || 0) > 0);
  }

  // else default false
  return false;
}

/**
 * get courier location details
 * 
 * @returns any
 */
export function getCourierLocationByStore(locationCode: LocationCode): any {
  switch (locationCode) {
    case 'cw-broadway':
      return {
        name: 'Broadway Post Shop',
        phone: '(02) 9207 7989',
        address: 'The Broadway Shopping Centre, Shop 21g 1-21 Bay Street, Glebe, NSW, 2037',
      }
    case 'cw-clinicboolaroo':
      return {
        name: 'Boolaroo LPO',
        phone: '(02) 4028 2307',
        address: '29 Main Road, Boolaroo, NSW, 2284',
      }
    case 'cw-edmondsonpark':
      return {
        name: 'Ingleburn Post Shop',
        phone: '(02) 4632 9001',
        address: '34 Oxford Road, Ingleburn, NSW, 2565',
      }
    case 'cw-glendale':
      return {
        name: 'Glendale Post Shop',
        phone: '(02) 4028 2307',
        address: 'Glendale Shopping Centre, Shop 1 387 Lake Road, Glendale, NSW, 2285',
      }
    case 'cw-wetherillpark':
      return {
        name: 'Wetherill Park LPO',
        phone: '(02) 4729 8630',
        address: 'Stockland Shopping Centre, Shop 255 561-583 Polding Street, Prairiewood, NSW, 2176',
      }
    default:
      console.warn('Must provide a supported LocationCode.')
      break;
  }
}

/**
 * filter an array of orders by date
 * 
 * @param orders Array<Order | IOrder | QueueElement>
 * @param startDate Date | undefined
 * @param endDate Date | undefined
 * @returns Array<Order | IOrder | QueueElement>
 */
// 
export function filterOrdersByDate(orders: Array<Order | IOrder | QueueElement>, startDate: Date | null, endDate: Date | null): Array<any> {
  return orders.filter(order => {
    const created = parseTimestamp(order.created_at).getTime();
    const start = startDate ? startDate.getTime() : null;
    const end = endDate ? endDate.getTime() : null;

    if (start && end) {
      return created > start && created < end;
    } else if (start) {
      return created > start;
    } else if (end) {
      return created < end;
    } else {
      return true;
    }
  })
}

/**
 * filter an array of orders by query from search bar
 * 
 * @param orders IOrders
 * @param query string
 * @returns IOrders
 */
// 
export function filterOrdersByQuery(orders: IOrders, query: string): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));

  const queryFragments = query.split(' ');
  mutatedOrders = mutatedOrders.filter(order => {
    const searchableFields = [
      // original fields
      order.platform_order_no,
      order.delivery_firstname,
      order.delivery_lastname,
      order.delivery_email,
      order.delivery_phone,
      order.billing_phone,
      // expanded fields
      order.order_number,
      // order.billing_firstname,
      // order.billing_lastname,
      // order.billing_email,
      // order.billing_phone,
      // order.billing_street,
      // order.billing_city,
      // order.billing_state_code,
      // order.billing_postcode,
      // order.delivery_street,
      // order.delivery_city,
      // order.delivery_state_code,
      // order.delivery_postcode,
    ].join(' ').toLowerCase();
    return queryFragments.every(fragment => searchableFields.includes(fragment.toLowerCase()));
  });

  return mutatedOrders;
}

export function filterQueueElementsByQuery(elements: Array<QueueElement>, query: string): Array<QueueElement> {
  let mutatedElements: Array<QueueElement> = JSON.parse(JSON.stringify(elements));

  const queryFragments = query.split(' ');
  mutatedElements = mutatedElements.filter(element => {
    const searchableFields = [
      element.customer_email,
      element.customer_phone,
      element.customer_first_name,
      element.customer_last_name,
      element.queue_element_number,
    ].join(' ').toLowerCase();
    return queryFragments.every(fragment => searchableFields.includes(fragment.toLowerCase()));
  });

  return mutatedElements;
}

/**
 * filter an array of orders by category from dashboard
 * 
 * @param orders IOrders
 * @param category Category
 * @returns IOrders
 */
// 
export function filterOrdersByCategory(orders: IOrders, category: Category, useDummyData?: boolean): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));

  mutatedOrders = mutatedOrders.filter(order => {
    switch (category) {
      case Category.OpenOrders:
        if (!objectIsCart(order)) {
          return [
            OrderStatus.New,
            OrderStatus.Fraud,
            OrderStatus.AwaitingPayment,
            OrderStatus.OnHold
          ].includes(order.status_code) && !orderIsPaymentOnly(order);
        } else {
          return objectIsOpenCart(order);
        }

      case Category.RequiresManifest:
        return [
          OrderStatus.AwaitingProcessing
        ].includes(order.status_code);

      case Category.DispatchCollection:
        if (useDummyData) {
          return [
            OrderStatus.AwaitingCourier,
            OrderStatus.AwaitingDropoff,
            OrderStatus.AwaitingPickup,
          ].includes(order.status_code)
        } else {
          // temp fix until scheduled manifest sync is implemented
          return (
            // any order is awaiting pickup
            [OrderStatus.AwaitingPickup].includes(order.status_code) ||
            // or any custom or sameday order is awaiting courier
            ([
              OrderDeliveryType.Custom,
              OrderDeliveryType.SameDay,
            ].includes(order.delivery_type_code) && order.status_code === OrderStatus.AwaitingCourier) ||
            // or any sameday order is in transit
            ([OrderDeliveryType.SameDay].includes(order.delivery_type_code) && order.status_code === OrderStatus.InTransit)
          )
        }

      case Category.ClosedOrders:
        if (useDummyData) {
          return [
            OrderStatus.Complete,
            OrderStatus.Cancelled,
            OrderStatus.Refunded,
            OrderStatus.Archived
          ].includes(order.status_code)
        } else {
          // temp fix until scheduled manifest sync is implemented
          return (
            // any order with one of these statuses
            [
              OrderStatus.Complete,
              OrderStatus.Cancelled,
              OrderStatus.Refunded,
              OrderStatus.Archived,
              OrderStatus.AwaitingDropoff,
            ].includes(order.status_code) ||
            // or any order that is NOT custom or sameday is awaiting courier
            ([
              OrderDeliveryType.Custom,
              OrderDeliveryType.SameDay,
            ].includes(order.delivery_type_code) === false && order.status_code === OrderStatus.AwaitingCourier) ||
            // or any order that is NOT sameday is in transit
            (order.delivery_type_code !== OrderDeliveryType.SameDay && order.status_code === OrderStatus.InTransit) ||
            // or any cart that is not open
            (objectIsCart(order) && !objectIsOpenCart(order)) ||
            // or any order from carts that does not have a courier type code
            ((order as any).cart_id && !order.courier_type_code)
          );
        }

      default:
        return false
    }
  });

  return mutatedOrders;
}

/**
 * filter an array of QueueElements by category from dashboard
 * 
 * @param elements Array<QueueElement>
 * @param category ScriptQueueCategory
 * @returns Array<QueueElement>
 */
// 
export function filterQueueElementsByCategory(elements: Array<QueueElement>, category: ScriptQueueCategory | QueueStatuses | string): Array<QueueElement> {
  if (!elements) return [];
  return elements.filter(element => {
    if (category === ScriptQueueCategory.Archived) {
      return element.queue_status_code === QueueStatuses.Collected || element.queue_status_code === QueueStatuses.Cancelled;
    } else {
      return element.queue_status_code === category
    }
  });
}

/**
 * get dashboard category for order
 * 
 * @param order IOrder
 * @returns Category
 */
// 
export function getOrderCategory(order: IOrder, useDummyData?: boolean): Category {
  let result = Category.OpenOrders;

  if (([OrderStatus.New, OrderStatus.Fraud, OrderStatus.AwaitingPayment, OrderStatus.OnHold].includes(order.status_code) || objectIsOpenCart(order))) {
    return Category.OpenOrders;
  }

  if ([OrderStatus.AwaitingProcessing].includes(order.status_code)) {
    return Category.RequiresManifest;
  }

  if (useDummyData) {
    if ([OrderStatus.AwaitingCourier, OrderStatus.AwaitingDropoff, OrderStatus.AwaitingPickup].includes(order.status_code)) {
      return Category.DispatchCollection;
    }
  } else {
    // temp fix until scheduled manifest sync is implemented
    if (
      // any order is awaiting pickup
      [OrderStatus.AwaitingPickup].includes(order.status_code) ||
      // or any custom or sameday order is awaiting courier
      ([OrderDeliveryType.Custom, OrderDeliveryType.SameDay].includes(order.delivery_type_code) && order.status_code === OrderStatus.AwaitingCourier) ||
      // or any sameday order is in transit
      ([OrderDeliveryType.SameDay].includes(order.delivery_type_code) && order.status_code === OrderStatus.InTransit)
    ) {
      return Category.DispatchCollection;
    }
  }

  if (useDummyData) {
    if ([OrderStatus.Complete, OrderStatus.Cancelled, OrderStatus.Refunded, OrderStatus.Archived].includes(order.status_code)) {
      return Category.ClosedOrders;
    }
  } else {
    // temp fix until scheduled manifest sync is implemented
    if (
      // any order with one of these statuses
      [
        OrderStatus.Complete,
        OrderStatus.Cancelled,
        OrderStatus.Refunded,
        OrderStatus.Archived,
        OrderStatus.AwaitingDropoff,
      ].includes(order.status_code) ||
      // or any order that is NOT custom or sameday is awaiting courier
      ([OrderDeliveryType.Custom, OrderDeliveryType.SameDay].includes(order.delivery_type_code) === false && order.status_code === OrderStatus.AwaitingCourier) ||
      // or any order that is NOT sameday is in transit
      (order.delivery_type_code !== OrderDeliveryType.SameDay && order.status_code === OrderStatus.InTransit) ||
      // or a cart which is not open
      (objectIsCart(order) && !objectIsOpenCart(order)) ||
      // or order is from a payment-only cart
      (orderIsPaymentOnly(order))
    ) {
      return Category.ClosedOrders;
    }
  }

  return result;
}

/**
 * get ScriptQueueCategory for QueueElement
 * 
 * @param element QueueElement
 * @returns ScriptQueueCategory
 */
// 
export function getQueueElementCategory(element: QueueElement, config: IScriptQueueConfig): ScriptQueueCategory {
  switch (element.queue_status_code) {
    case QueueStatuses.New:
      return ScriptQueueCategory.New;
    case QueueStatuses.DispensingInProgress:
      return ScriptQueueCategory.Dispensing;
    case QueueStatuses.Dispensed:
      // return config.use_incoming_queue ? ScriptQueueCategory.Dispensed : ScriptQueueCategory.New;
      return ScriptQueueCategory.New;
    case QueueStatuses.AwaitingCollection:
      return ScriptQueueCategory.AwaitingCollection;
    case QueueStatuses.Collected:
    case QueueStatuses.Cancelled:
      return ScriptQueueCategory.Archived;
  }
}

/**
 * filter an array of orders by subcategory
 * 
 * @param orders IOrders
 * @param subcategory Subcategory
 * @returns IOrders
 */
// 
export function filterOrdersBySubcategory(orders: IOrders, subcategory: Subcategory): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));
  mutatedOrders = mutatedOrders.filter(order => {
    switch (subcategory) {
      // open
      case Subcategory.IncomingOrders:
        return order.status_code === OrderStatus.New;
      case Subcategory.FraudOrders:
        return order.status_code === OrderStatus.Fraud;
      case Subcategory.InProgress:
        return order.status_code === OrderStatus.OnHold;
      case Subcategory.PendingPayment:
        return order.status_code === OrderStatus.AwaitingPayment || objectIsOpenCart(order);
      // requires manifest
      case Subcategory.ManifestPickup:
        return order.status_code === OrderStatus.AwaitingProcessing && order.delivery_model_code === DeliveryModelCode.Pickup;
      case Subcategory.ManifestDropoff:
        return order.status_code === OrderStatus.AwaitingProcessing && order.delivery_model_code === DeliveryModelCode.Dropoff;
      // daspatch & collection
      case Subcategory.DispatchPickup:
        return (
          [OrderStatus.AwaitingCourier, OrderStatus.InTransit].includes(order.status_code) &&
          [OrderDeliveryType.Custom, OrderDeliveryType.SameDay].includes(order.delivery_type_code)
        )
      case Subcategory.DispatchDropoff:
        return order.status_code === OrderStatus.AwaitingDropoff;
      case Subcategory.DispatchCollection:
        return order.status_code === OrderStatus.AwaitingPickup;
      // closed
      case Subcategory.CompletedOrders:
        // return order.status_code === OrderStatus.Complete; 
        return (
          // any order with one of these statuses
          [
            OrderStatus.Complete,
            OrderStatus.Archived,
            OrderStatus.AwaitingDropoff,
          ].includes(order.status_code) ||
          // or any order that is NOT custom or sameday is awaiting courier
          (order.status_code == OrderStatus.AwaitingCourier && [OrderDeliveryType.Custom, OrderDeliveryType.SameDay].includes(order.delivery_type_code) === false) ||
          // or any order that is NOT sameday is in transit
          (order.status_code == OrderStatus.InTransit && [OrderDeliveryType.SameDay].includes(order.delivery_type_code) === false) ||
          // or any cart that is not open
          (objectIsCart(order) && !objectIsOpenCart(order)) ||
          // or order is from a payment-only cart
          (orderIsPaymentOnly(order))
        )
      case Subcategory.CancelledOrders:
        return order.status_code === OrderStatus.Cancelled;
      case Subcategory.PartialRefundOrders:
        return isPartialRefund(order) && order.order_state_code === OrderStates.Complete;
      case Subcategory.RefundOrders:
        return order.status_code === OrderStatus.Refunded;

      // case Subcategory.RequiresAction:
      //   return order.status_code === OrderStatus.AwaitingProcessing;
      // case Subcategory.DispatchOrganised:
      //   return order.status_code === OrderStatus.AwaitingDropoff
      //     || order.status_code === OrderStatus.AwaitingCourier;

      default:
        return false
    }
  });

  return mutatedOrders;
}

/**
 * get dashboard subcategory for order
 * 
 * @param order IOrder
 * @returns Subcategory
 */
// 
export function getOrderSubcategory(order: IOrder): Subcategory | null {
  let result = null;

  // open
  if (order.status_code === OrderStatus.New) {
    if (!orderIsPaymentOnly(order)) {
      result = Subcategory.IncomingOrders;
    } else {
      result = Subcategory.CompletedOrders;
    }
  } else if (order.status_code === OrderStatus.Fraud) {
    result = Subcategory.FraudOrders;
  } else if (order.status_code === OrderStatus.AwaitingPayment || objectIsOpenCart(order)) {
    result = Subcategory.PendingPayment;
  } else if (order.status_code === OrderStatus.OnHold) {
    result = Subcategory.InProgress;

    // requires manifest
  } else if (order.status_code === OrderStatus.AwaitingProcessing && order.delivery_model_code === DeliveryModelCode.Pickup) {
    result = Subcategory.ManifestPickup;
  } else if (order.status_code === OrderStatus.AwaitingProcessing && order.delivery_model_code === DeliveryModelCode.Dropoff) {
    result = Subcategory.ManifestDropoff;

    // dispatch & collection
  } else if (order.status_code === OrderStatus.AwaitingCourier) {
    if (order.delivery_type_code !== OrderDeliveryType.Custom && order.delivery_type_code !== OrderDeliveryType.SameDay) {
      result = Subcategory.CompletedOrders
    } else {
      result = Subcategory.DispatchPickup;
    }
  } else if (order.status_code === OrderStatus.InTransit) {
    if (order.delivery_type_code !== OrderDeliveryType.SameDay) {
      result = Subcategory.CompletedOrders
    } else {
      result = Subcategory.DispatchPickup;
    }
  } else if (order.status_code === OrderStatus.AwaitingDropoff) {
    result = Subcategory.DispatchDropoff;
  } else if (order.status_code === OrderStatus.AwaitingPickup) {
    result = Subcategory.DispatchCollection;

    // closed
  } else if (order.status_code === OrderStatus.Cancelled) {
    result = Subcategory.CancelledOrders;
  } else if (isPartialRefund(order) && order.order_state_code === OrderStates.Complete) {
    result = Subcategory.PartialRefundOrders;
  } else if (order.status_code === OrderStatus.Refunded) {
    result = Subcategory.RefundOrders;
  } else if (
    // order.status_code === OrderStatus.Complete; 
    [
      OrderStatus.Complete,
      OrderStatus.Archived,
      OrderStatus.AwaitingDropoff,
    ].includes(order.status_code) || (objectIsCart(order) && !objectIsOpenCart(order))
    ) {
    result = Subcategory.CompletedOrders;
  }

  return result;
}

/**
 * get dashboard subcategory for QueueElement
 * 
 * @param element QueueElement
 * @returns Subcategory
 */
// 
export function getQueueElementSubcategory(element: QueueElement): ScriptQueueSubcategory | null {
  switch (element.queue_status_code) {
    // statuses with relevant subcategories
    case QueueStatuses.New:
    case QueueStatuses.Dispensed:
      if (element.is_on_hold === false) {
        return ScriptQueueSubcategory.AwaitingProcessing;
      } else {
        return ScriptQueueSubcategory.OnHold
      }
    // case QueueStatuses.Dispensed:
    //   return ScriptQueueSubcategory.PreCollection
    // all other statuses
    case QueueStatuses.DispensingInProgress:
    case QueueStatuses.AwaitingCollection:
    case QueueStatuses.Collected:
    case QueueStatuses.Cancelled:
    default:
      return null;
  }
}

/**
 * filter an array of orders by query from search bar and category from dashboard
 * 
 * @param orders IOrders
 * @param category Category | undefined
 * @param query string | undefined
 * @param subcategory Subcategory | undefined
 * @returns IOrders
 */
// 
export function filterOrders(orders: IOrders, category: Category | undefined, query: string | undefined, subcategory?: Subcategory, useDummyData?: boolean): IOrders {
  // return early if no orders
  if (orders.length === 0) {
    return [];
  }

  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));
  mutatedOrders = mutatedOrders.filter(order => order.is_visible !== false);
  if (category) mutatedOrders = filterOrdersByCategory(mutatedOrders, category, useDummyData);
  if (subcategory) mutatedOrders = filterOrdersBySubcategory(mutatedOrders, subcategory);
  if (query) mutatedOrders = filterOrdersByQuery(mutatedOrders, query);
  return mutatedOrders;
}

/**
 * sort an array of orders by specified fields
 * currently supports 'deliveryType', 'created', 'ordered', 'updated', 'closed', 'urgent' fields and 'reverse' modifier
 * eg. ['deliveryType', 'created'] or ['updated-reverse']
 * 
 * @param orders IOrders
 * @param sortBy Array<string>
 * @returns IOrders
 */
// 
export function sortOrders(orders: IOrders, sortBy: Array<string>, closeTimestamp?: number): IOrders {
  let mutatedOrders: Array<IOrderTemp> = JSON.parse(JSON.stringify(orders));

  sortBy.reverse().forEach(sortType => {
    // sort by specified type
    if (sortType.includes('created')) {
      // sort by date created (default oldest first)
      mutatedOrders.forEach(order => {
        order.parsedDate = parseTimestamp(order.created_at);
      })
      mutatedOrders = mutatedOrders.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    } else if (sortType.includes('ordered')) {
      mutatedOrders.forEach(order => {
        order.parsedDate = parseTimestamp(order.created_at);
      })
      mutatedOrders = mutatedOrders.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    } else if (sortType.includes('updated')) {
      // sort by date updated (default oldest first) -- (falls back to date created)
      mutatedOrders.forEach(order => {
        if (order.updated_at) {
          order.parsedDate = parseTimestamp(order.updated_at);
        } else {
          order.parsedDate = parseTimestamp(order.created_at);
        }
      })
      mutatedOrders = mutatedOrders.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    } else if (sortType.includes('completed')) {
      // sort by date completed (default oldest first) -- (falls back to date created)
      mutatedOrders.forEach(order => {
        if (order.completed_at) {
          order.parsedDate = parseTimestamp(order.completed_at);
        } else {
          order.parsedDate = parseTimestamp(order.created_at);
        }
      })
      mutatedOrders = mutatedOrders.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    } else if (sortType.includes('deliveryType')) {
      // sort by delivery type (default order below)
      const types = ['express', 'same_day', 'pickup', 'standard'];
      mutatedOrders = mutatedOrders.sort((a, b) => {
        if (types.indexOf(a.delivery_type_code) < types.indexOf(b.delivery_type_code)) {
          return -1;
        }
        if (types.indexOf(a.delivery_type_code) > types.indexOf(b.delivery_type_code)) {
          return 1;
        }
        return 0;
      })
    } else if (sortType.includes('sameday')) {
      // sort sameday (doordash) orders to top
      mutatedOrders = mutatedOrders.sort((a, b) => {
        if (a.delivery_type_code === OrderDeliveryType.SameDay && b.delivery_type_code !== OrderDeliveryType.SameDay) {
          return -1;
        }
        if (a.delivery_type_code !== OrderDeliveryType.SameDay && b.delivery_type_code === OrderDeliveryType.SameDay) {
          return 1;
        }
        return 0;
      })
    } else if (sortType.includes('overdue')) {
      // sort by overdue urgency
      const types = [OrderUrgency.Overdue, OrderUrgency.Standard];
      mutatedOrders = mutatedOrders.sort((a, b) => {
        let urgencyA = getOrderUrgency(a, closeTimestamp);
        let urgencyB = getOrderUrgency(b, closeTimestamp);
        if (urgencyA === OrderUrgency.Warning) urgencyA = OrderUrgency.Standard;
        if (urgencyB === OrderUrgency.Warning) urgencyB = OrderUrgency.Standard;

        if (types.indexOf(urgencyA) < types.indexOf(urgencyB)) {
          return -1;
        }
        if (types.indexOf(urgencyA) > types.indexOf(urgencyB)) {
          return 1;
        }
        return 0;
      })
    }

    // reverse if necessary
    if (sortType.includes('reverse')) {
      mutatedOrders.reverse();
    }
  })

  return mutatedOrders as IOrders;
}

export function sortQueueElements(elements: Array<QueueElement>, sortBy: Array<string>, closeTimestamp?: number): Array<QueueElement> {
  let mutatedElements: Array<any> = JSON.parse(JSON.stringify(elements));

  sortBy.reverse().forEach(sortType => {
    // sort by specified type
    if (sortType.includes('created')) {
      // sort by date created (default oldest first)
      mutatedElements.forEach(element => {
        element.parsedDate = parseTimestamp(element.created_at);
      })
      mutatedElements = mutatedElements.sort((a, b) => {
        return a.parsedDate! < b.parsedDate! ? -1 : 1;
      })
    }

    // reverse if necessary
    if (sortType.includes('reverse')) {
      mutatedElements.reverse();
    }
  })

  return mutatedElements as Array<QueueElement>;
}

/**
 * filter an array of orders by query from search bar and category from dashboard
 * and sort by date (oldest first) and delivery type
 * 
 * @param orders IOrders
 * @param category Category | undefined
 * @param query string | undefined
 * @param subcategory Subcategory | undefined
 * @returns IOrders
 */
// 
export function filteredSortedOrders(orders: IOrders, category: Category | undefined, query: string | undefined, subcategory?: Subcategory, closeTimestamp?: number, useDummyData?: boolean): IOrders {
  let mutatedOrders: IOrders = JSON.parse(JSON.stringify(orders));
  mutatedOrders = filterOrders(mutatedOrders, category, query, subcategory, useDummyData);
  // unique sort order for some categories
  let sortBy = ['sameday', 'overdue', 'ordered'];
  if (category === Category.ClosedOrders) sortBy = ['completed-reverse'];
  if (category === Category.DispatchCollection) sortBy = ['updated-reverse'];
  if (category === Category.RequiresManifest) sortBy = ['updated-reverse'];
  mutatedOrders = sortOrders(mutatedOrders, sortBy, closeTimestamp);
  return mutatedOrders;
}

/**
 * return urgency status of an order based on delivery type
 * 
 * @param order IOrder
 * @returns OrderUrgency
 */
// 
const hour = 1000 * 60 * 60;
export function getOrderUrgency(order: IOrder, closeTimestamp?: number): OrderUrgency {
  const timeAgoOrdered = new Date().getTime() - parseTimestamp(order.created_at).getTime();
  const timeUntilClose = closeTimestamp ? closeTimestamp - new Date().getTime() : null;

  // orders that have already been processed are always Standard urgency
  if (
    [
      OrderStatus.New,
      OrderStatus.AwaitingProcessing,
      OrderStatus.AwaitingPickup,
      // OrderStatus.AwaitingCourier,
      // OrderStatus.AwaitingProcessing,
    ].includes(order.status_code) === false
  ) {
    return OrderUrgency.Standard;
  }

  // Standard delivery
  if (order.delivery_type_code === OrderDeliveryType.Standard) {
    if (timeAgoOrdered > (48 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (36 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }

  // Express delivery
  if (order.delivery_type_code === OrderDeliveryType.Express) {
    if (timeAgoOrdered > (24 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (8 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }

  // Same-Day delivery
  if (order.delivery_type_code === OrderDeliveryType.SameDay) {
    if (timeAgoOrdered > (0.75 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (0.33 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }

  // Click & Collect
  if (order.delivery_type_code === OrderDeliveryType.Pickup) {
    if (timeAgoOrdered > (24 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (4 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }

  // Custom courier
  if (order.delivery_type_code === OrderDeliveryType.Custom) {
    if (timeAgoOrdered > (48 * hour)) {
      return OrderUrgency.Overdue;
    } else if (timeAgoOrdered > (24 * hour)) {
      return OrderUrgency.Warning;
    } else {
      return OrderUrgency.Standard;
    }
  }

  // default case
  return OrderUrgency.Standard;
}

/**
 * download a CSV file containing the supplied data
 * 
 * @param rows Array<Array<any>>
 * @param filename string
 */
// 
export function downloadCsvFromRows(rows: Array<Array<any>>, filename: string): void {
  // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  let csvContent = "data:text/csv;charset=utf-8," 
    + rows.map(e => e.join(",")).join("\n");

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link); // Required for FF
  
  link.click();
}

/**
 * determine if any of any Order's Items have requires_contact === true
 * (indicates item qty is >= threshold in Magento)
 * 
 * @param order Order
 */
// 
export function orderQuantityRequiresContact(order: IOrder): boolean {
  if (!order.items) {
    return false;
  } else {
    return order.items.some(item => item.requires_contact);
  }
}

/**
 * determine if any of any Order's Items are scheduled
 * 
 * @param order Order
 */
// 
export function orderScheduleRequiresContact(order: IOrder): boolean {
  if (!order.items) {
    return false;
  } else {
    return order.items.some(item => item.drug_schedule);
  }
}

/**
 * determine if Order requires pharmacist contact before processing
 * 
 * @param order Order
 */
// 
export function orderRequiresContact(order: IOrder): boolean {
  return orderQuantityRequiresContact(order) || orderScheduleRequiresContact(order);
}

/**
 * Recursively find any instances of a value in a nested JSON object and return the parent object
 * 
 * @param object any
 */
// 
export function searchMkRecursive(object: any, input: string, prevObject: any = null): any {
  let found = [];
  let obj: any = {};

  for (let key in object) {
    if (typeof object[key] === "object") {
      found.push(...searchMkRecursive(object[key], input, object));
    } else {
      if (key === input || object[key] === input) {
        obj[key] = object[key]
        found.push(object);
        obj = {}
      }
    }
  }
  return found;
}

/**
 * Retrieve address fields from an array of Google Maps AddressComponents
 * 
 * @param object any
 */
// 
export const getFieldsFromGoogleAddressComponents = (displayName: string, addressComponents: Array<google.maps.places.AddressComponent>): Partial<PaymentAddress> => {
  let subpremise = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('subpremise'));
  let street_number = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('street_number'));
  let route = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('route'));
  let locality = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('locality'));
  let state = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('administrative_area_level_1'));
  let postal_code = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('postal_code'));

  return {
    street1: street_number && route ? `${street_number.shortText} ${route.shortText}` : '',
    street2: subpremise ? subpremise.shortText || '' : '',
    suburb: locality ? locality.shortText || '' : '',
    state: state ? state.shortText as AddressState || '' : '',
    postcode: postal_code ? postal_code.shortText || '' : '',
  }
}

/**
 * Format SparrowCart link from Cart reference
 * 
 * @param reference string
 * @param includeProtocol boolean
 */
// 
export const formatCartLink = (reference: string, includeProtocol = true): string => {
  const domain = 'cart.sparrowhub.com.au';
  const pathPrefix = 'c';
  return includeProtocol
    ? `https://${domain}/${pathPrefix}/${reference}`
    : `${domain}/${reference}`;
}

export enum Environment {
  Local = 'local',
  Staging = 'staging',
  Production = 'production'
}
/**
 * Determine current environment
 * TODO: replace with env variable
 */
// 
export const currentEnvironment = (): Environment => {
  switch (process.env.REACT_APP_BACKEND_API_HOST) {
    case 'localhost':
      return Environment.Local;
    case 's.sparrowhub.com.au':
      return Environment.Staging;
    case 'partner.sparrowhub.com.au':
      return Environment.Production;
    default:
      return Environment.Production;
  }
}

/**
 * determine if an object is a cart of any state
 * 
 * @param object any
 * @returns boolean
 */
export const objectIsCart = (object: any): boolean => {
  return Object.hasOwn(object, 'maximum_uses')
    && Object.hasOwn(object, 'requires_payment')
    && Object.hasOwn(object, 'requires_delivery');
}

/**
 * determine if an object is an open cart
 * 
 * @param object any
 * @returns boolean
 */
export const objectIsOpenCart = (object: any): boolean => {
  return Object.hasOwn(object, 'maximum_uses')
    && Object.hasOwn(object, 'requires_payment')
    && Object.hasOwn(object, 'requires_delivery')
    && Object.hasOwn(object, 'uses')
    && Object.hasOwn(object, 'is_enabled')
    && object.uses < object.maximum_uses
    && object.is_enabled;
}

/**
 * determine if an order comes from a payment-only cart
 * 
 * @param order any
 * @returns boolean
 */
export const orderIsPaymentOnly = (object: any): boolean => {
  return object.cart_id !== null && 
    object.courier_type_code === null && 
    object.delivery_type_code !== OrderDeliveryType.Pickup;
}

/**
 * parse OrderItem options string
 * 
 * @param item OrderItem
 * @returns any | null
 */
export const getOrderItemOptions = (item: any): Array<any> => {
  try {
    const data = JSON.parse(item.options);
    if (Array.isArray(data)) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}